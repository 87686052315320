import './App.css';

import { useState, useRef, useEffect } from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AppContext } from './scripts/context'

import Header from './components/header'
import Footer from './components/footer'
import Page from './components/page'
import Error from './components/error'

function App() {

    const [cartContentState, setCartContentState] = useState(JSON.parse(localStorage.getItem("cartContent")));
    const [checkoutPanelState, setCheckoutPanelState] = useState(false);
    const [checkoutPanelContentState, setCheckoutPanelContentState] = useState("cart");
    const [checkoutPanelCountryState, setCheckoutPanelCountryState] = useState("select_country");
    const [checkoutPanelButtonState, setCheckoutPanelButtonState] = useState(!cartContentState ? false : true);
    const [paypalSuccessState, setPaypalSuccessState] = useState(false);
    const [shippingCostState, setShippingCostState] = useState(0);
    const [mediaLoaderVisState, setMediaLoaderVisState] = useState("visible");
    const [mediaLoaderOpaState, setMediaLoaderOpaState] = useState(1);

    const exRateChfUsdRef = useRef(1);

    const menuExpandedRef = useRef(true);
    const menuRunningRef = useRef(false);
    const menuLockedRef = useRef(false);
    const menuElementsRefs = {
        pathHome: useRef(null),
        circleHome0: useRef(null),
        circleHome1: useRef(null),
        textHomeH: useRef(null),
        textHomeO: useRef(null),
        textHomeM: useRef(null),
        textHomeE: useRef(null),
        linkHome: useRef(null),
        pathDigital: useRef(null),
        circleDigital0: useRef(null),
        circleDigital1: useRef(null),
        textDigitalD: useRef(null),
        textDigitalI0: useRef(null),
        textDigitalG: useRef(null),
        textDigitalI1: useRef(null),
        textDigitalT: useRef(null),
        textDigitalA: useRef(null),
        textDigitalL: useRef(null),
        linkDigital: useRef(null),
        pathGames: useRef(null),
        circleGames0: useRef(null),
        circleGames1: useRef(null),
        textGamesG: useRef(null),
        textGamesA: useRef(null),
        textGamesM: useRef(null),
        textGamesE: useRef(null),
        textGamesS: useRef(null),
        linkGames: useRef(null),
        pathDesign: useRef(null),
        circleDesign0: useRef(null),
        circleDesign1: useRef(null),
        textDesignD: useRef(null),
        textDesignE: useRef(null),
        textDesignS: useRef(null),
        textDesignI: useRef(null),
        textDesignG: useRef(null),
        textDesignN: useRef(null),
        linkDesign: useRef(null),
        pathStore: useRef(null),
        circleStore0: useRef(null),
        circleStore1: useRef(null),
        textStoreS: useRef(null),
        textStoreT: useRef(null),
        textStoreO: useRef(null),
        textStoreR: useRef(null),
        textStoreE: useRef(null),
        linkStore: useRef(null),
        buttonMenu: useRef(null),
        menuBg: useRef(null)
    };
    const pageElementsRefs = {
        mainContainerContent: useRef(null),
        bodyElement: useRef(document.getElementsByTagName("body")[0])
    };

    const scrollPrevPosRef = useRef();

    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    // Function to fetch the shipping estimate at localhost:3001/api/countries
  	const fetchExRateChfUsd = async () => {
        try {

              const response = await fetch(`${backendUrl}/api/exchange_rate_chf_usd`, {
                    method: 'GET',
                    headers: {
                          'Content-Type': 'application/json',
                    },
              });

              if (!response.ok) {
                    throw new Error(`Network response was not ok, status: ${response.status}`);
              };

              const data = await response.json();
              const exRateChfUsd = data.exRateChfUsd;

              // Validate response is a number
              if (isNaN(exRateChfUsd)) {
                    console.error('Invalid exchange rate:', exRateChfUsd);
              }

              exRateChfUsdRef.current = exRateChfUsd;

        } catch (error) {

              console.error('Failed to fetch shipping estimate:', error.message);

        };
    };

    function setMenuLocked(value) {
        menuLockedRef.current = value;
    };

    function scrollToTitle(scrollBehavior) {

        menuLockedRef.current = true;

        let intervalToTitleMenu = setInterval(lockToTitleMenu, 25);
        function lockToTitleMenu() {
            if (getScrollPosition() > 0.05) {
                clearInterval(intervalToTitleMenu);
                menuLockedRef.current = false;
                handleMenu("scroll");
            };
        };

        window.scrollTo({
            top: getFadeEndTopPixel(),
            left: 0,
            behavior: scrollBehavior,
        });
    };

    function scrollToOverview(scrollBehavior) {

        menuLockedRef.current = true;

        let intervalToOverviewMenu = setInterval(lockToOverviewMenu, 25);
        function lockToOverviewMenu() {
            if (getScrollPosition() > 0.05) {
                clearInterval(intervalToOverviewMenu);
                menuLockedRef.current = false;
                handleMenu("scroll");
            };
        };

        window.scrollTo({
            top: getFadeStartBottomPixel(),
            left: 0,
            behavior: scrollBehavior,
        });

    };

    function scrollToTop(scrollBehavior) {

        menuLockedRef.current = true;

        let intervalToTop = setInterval(lockToTop, 25);
        function lockToTop() {
            if (getScrollPosition() < 0.05) {
                clearInterval(intervalToTop);
                menuLockedRef.current = false;
                handleMenu("scroll");
            };
        };

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: scrollBehavior,
        });

    };

    function scrollToBottom(scrollBehavior) {

        menuLockedRef.current = true;

        let intervalToBottom = setInterval(lockToBottom, 25);
        function lockToBottom() {
            if (getScrollPosition() > 0.05) {
                clearInterval(intervalToBottom);
                menuLockedRef.current = false;
                handleMenu("scroll");
            };
        };

        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: scrollBehavior,
        });

    };

    function getScrollPosition() {

        let scrollPos = (window.scrollY/(document.body.scrollHeight - window.innerHeight));

        if (scrollPos < 0.01) {
            scrollPos = 0;
        } else if (scrollPos > 0.99) {
            scrollPos = 1;
        };

        return scrollPos;

    };

    function getFadeEndTop() {

        return (1 / document.body.scrollHeight * window.innerHeight);

    };

    function getFadeStartBottom() {

        return (1 / document.body.scrollHeight * (document.body.scrollHeight - window.innerHeight));

    };

    function getFadeEndTopPixel() {

        return (getFadeEndTop() * (document.body.scrollHeight - window.innerHeight));

    };

    function getFadeStartBottomPixel() {

        return (getFadeStartBottom() * (document.body.scrollHeight - window.innerHeight));

    };

    function handleMenu(type) {

        if (menuRunningRef.current === false && menuLockedRef.current === false) {

            menuRunningRef.current = true;

            const intervalSpeed = 0.35;
            const intervalDelay = 5;

            let scrollPos = getScrollPosition();

            function expandMenu() {

                let menuBgOpacity = 0;
                let intervalMenuBgTimePrevious = Date.now();
                let intervalMenuBgOpacity = setInterval(offsetCircleHome, intervalDelay);
                function offsetCircleHome() {
                    let intervalDeltaTime = Date.now() - intervalMenuBgTimePrevious;
                    if (menuBgOpacity < 0.75) {
                        menuBgOpacity = Math.min(menuBgOpacity + intervalDeltaTime * 0.002, 0.75);
                        menuElementsRefs.menuBg.current.setAttribute("opacity", menuBgOpacity);
                        intervalMenuBgTimePrevious = Date.now();
                    } else {
                        clearInterval(intervalMenuBgOpacity);
                    };
                };

                menuElementsRefs.buttonMenu.current.setAttribute("display", "none");

                function setHomeVisible() {
                    setTimeout(function() {
                        menuElementsRefs.textHomeH.current.setAttribute("visibility", "visible");
                    }, 30);
                    setTimeout(function() {
                        menuElementsRefs.textHomeO.current.setAttribute("visibility", "visible");
                    }, 60);
                    setTimeout(function() {
                        menuElementsRefs.textHomeM.current.setAttribute("visibility", "visible");
                    }, 90);
                    setTimeout(function() {
                        menuElementsRefs.textHomeE.current.setAttribute("visibility", "visible");
                        menuElementsRefs.linkHome.current.setAttribute("visibility", "visible");
                    }, 120);
                };

                let pathHomeOffset = 169;
                let intervalHomeTimePrevious = Date.now();
                let intervalHome = setInterval(offsetHome, intervalDelay);
                function offsetHome() {
                    let intervalDeltaTime = Date.now() - intervalHomeTimePrevious;
                    if (pathHomeOffset > 0) {
                        pathHomeOffset = Math.max(pathHomeOffset - intervalDeltaTime * intervalSpeed, 0);
                        menuElementsRefs.pathHome.current.setAttribute("stroke-dashoffset", pathHomeOffset);
                        intervalHomeTimePrevious = Date.now();
                    } else {
                        clearInterval(intervalHome);
                        setHomeVisible();

                        let circleHomeOffset = 13;
                        let intervalHomeCircleTimePrevious = Date.now();
                        let intervalHomeCircle = setInterval(offsetHomeCircle, intervalDelay);
                        function offsetHomeCircle() {
                            let intervalDeltaTime = Date.now() - intervalHomeCircleTimePrevious;
                            if (circleHomeOffset > 0) {
                                circleHomeOffset = Math.max(circleHomeOffset - intervalDeltaTime * intervalSpeed, 0);
                                menuElementsRefs.circleHome0.current.setAttribute("stroke-dashoffset", circleHomeOffset);
                                menuElementsRefs.circleHome1.current.setAttribute("stroke-dashoffset", circleHomeOffset);
                                intervalHomeCircleTimePrevious = Date.now();
                            } else {
                                clearInterval(intervalHomeCircle);
                            };
                        };
                    };
                };

                function setDigitalVisible() {
                    setTimeout(function() {
                        menuElementsRefs.textDigitalD.current.setAttribute("visibility", "visible");
                    }, 30);
                    setTimeout(function() {
                        menuElementsRefs.textDigitalI0.current.setAttribute("visibility", "visible");
                    }, 60);
                    setTimeout(function() {
                        menuElementsRefs.textDigitalG.current.setAttribute("visibility", "visible");
                    }, 90);
                    setTimeout(function() {
                        menuElementsRefs.textDigitalI1.current.setAttribute("visibility", "visible");
                    }, 120);
                    setTimeout(function() {
                        menuElementsRefs.textDigitalT.current.setAttribute("visibility", "visible");
                    }, 150);
                    setTimeout(function() {
                        menuElementsRefs.textDigitalA.current.setAttribute("visibility", "visible");
                    }, 180);
                    setTimeout(function() {
                        menuElementsRefs.textDigitalL.current.setAttribute("visibility", "visible");
                        menuElementsRefs.linkDigital.current.setAttribute("visibility", "visible");
                    }, 210);
                };

                let pathDigitalOffset = 152;
                let intervalDigitalTimePrevious = Date.now();
                let intervalDigital = setInterval(offsetDigital, intervalDelay);
                function offsetDigital() {
                    let intervalDeltaTime = Date.now() - intervalDigitalTimePrevious;
                    if (pathDigitalOffset > 0) {
                        pathDigitalOffset = Math.max(pathDigitalOffset - intervalDeltaTime * intervalSpeed, 0);
                        menuElementsRefs.pathDigital.current.setAttribute("stroke-dashoffset", pathDigitalOffset);
                        intervalDigitalTimePrevious = Date.now();
                    } else {
                        clearInterval(intervalDigital);
                        setDigitalVisible();

                        let circleDigitalOffset = 13;
                        let intervalDigitalCircleTimePrevious = Date.now();
                        let intervalDigitalCircle = setInterval(offsetDigitalCircle, intervalDelay);
                        function offsetDigitalCircle() {
                            let intervalDeltaTime = Date.now() - intervalDigitalCircleTimePrevious;
                            if (circleDigitalOffset > 0) {
                                circleDigitalOffset = Math.max(circleDigitalOffset - intervalDeltaTime * intervalSpeed, 0);
                                menuElementsRefs.circleDigital0.current.setAttribute("stroke-dashoffset", circleDigitalOffset);
                                menuElementsRefs.circleDigital1.current.setAttribute("stroke-dashoffset", circleDigitalOffset);
                                intervalDigitalCircleTimePrevious = Date.now();
                            } else {
                                clearInterval(intervalDigitalCircle);
                            };
                        };
                    };
                };

                function setGamesVisible() {
                    setTimeout(function() {
                        menuElementsRefs.textGamesG.current.setAttribute("visibility", "visible");
                    }, 30);
                    setTimeout(function() {
                        menuElementsRefs.textGamesA.current.setAttribute("visibility", "visible");
                    }, 60);
                    setTimeout(function() {
                        menuElementsRefs.textGamesM.current.setAttribute("visibility", "visible");
                    }, 90);
                    setTimeout(function() {
                        menuElementsRefs.textGamesE.current.setAttribute("visibility", "visible");
                    }, 120);
                    setTimeout(function() {
                        menuElementsRefs.textGamesS.current.setAttribute("visibility", "visible");
                        menuElementsRefs.linkGames.current.setAttribute("visibility", "visible");
                    }, 150);
                };

                let pathGamesOffset = 162;
                let intervalGamesTimePrevious = Date.now();
                let intervalGames = setInterval(offsetGames, intervalDelay);
                function offsetGames() {
                    let intervalDeltaTime = Date.now() - intervalGamesTimePrevious;
                    if (pathGamesOffset > 0) {
                        pathGamesOffset = Math.max(pathGamesOffset - intervalDeltaTime * intervalSpeed, 0);
                        menuElementsRefs.pathGames.current.setAttribute("stroke-dashoffset", pathGamesOffset);
                        intervalGamesTimePrevious = Date.now();
                    } else {
                        clearInterval(intervalGames);
                        setGamesVisible();

                        let circleGamesOffset = 13;
                        let intervalGamesCircleTimePrevious = Date.now();
                        let intervalGamesCircle = setInterval(offsetGamesCircle, intervalDelay);
                        function offsetGamesCircle() {
                            let intervalDeltaTime = Date.now() - intervalGamesCircleTimePrevious;
                            if (circleGamesOffset > 0) {
                                circleGamesOffset = Math.max(circleGamesOffset - intervalDeltaTime * intervalSpeed, 0);
                                menuElementsRefs.circleGames0.current.setAttribute("stroke-dashoffset", circleGamesOffset);
                                menuElementsRefs.circleGames1.current.setAttribute("stroke-dashoffset", circleGamesOffset);
                                intervalGamesCircleTimePrevious = Date.now();
                            } else {
                                clearInterval(intervalGamesCircle);
                            };
                        };
                    };
                };

                function setDesignVisible() {
                    setTimeout(function() {
                        menuElementsRefs.textDesignD.current.setAttribute("visibility", "visible");
                    }, 30);
                    setTimeout(function() {
                        menuElementsRefs.textDesignE.current.setAttribute("visibility", "visible");
                    }, 60);
                    setTimeout(function() {
                        menuElementsRefs.textDesignS.current.setAttribute("visibility", "visible");
                    }, 90);
                    setTimeout(function() {
                        menuElementsRefs.textDesignI.current.setAttribute("visibility", "visible");
                    }, 120);
                    setTimeout(function() {
                        menuElementsRefs.textDesignG.current.setAttribute("visibility", "visible");
                    }, 150);
                    setTimeout(function() {
                        menuElementsRefs.textDesignN.current.setAttribute("visibility", "visible");
                        menuElementsRefs.linkDesign.current.setAttribute("visibility", "visible");
                    }, 180);
                };

                let pathDesignOffset = 210;
                let intervalDesignTimePrevious = Date.now();
                let intervalDesign = setInterval(offsetDesign, intervalDelay);
                function offsetDesign() {
                    let intervalDeltaTime = Date.now() - intervalDesignTimePrevious;
                    if (pathDesignOffset > 0) {
                        pathDesignOffset = Math.max(pathDesignOffset - intervalDeltaTime * intervalSpeed, 0);
                        menuElementsRefs.pathDesign.current.setAttribute("stroke-dashoffset", pathDesignOffset);
                        intervalDesignTimePrevious = Date.now();
                    } else {
                        clearInterval(intervalDesign);
                        setDesignVisible();

                        let circleDesignOffset = 13;
                        let intervalDesignCircleTimePrevious = Date.now();
                        let intervalDesignCircle = setInterval(offsetDesignCircle, intervalDelay);
                        function offsetDesignCircle() {
                            let intervalDeltaTime = Date.now() - intervalDesignCircleTimePrevious;
                            if (circleDesignOffset > 0) {
                                circleDesignOffset = Math.max(circleDesignOffset - intervalDeltaTime * intervalSpeed, 0);
                                menuElementsRefs.circleDesign0.current.setAttribute("stroke-dashoffset", circleDesignOffset);
                                menuElementsRefs.circleDesign1.current.setAttribute("stroke-dashoffset", circleDesignOffset);
                                intervalDesignCircleTimePrevious = Date.now();
                            } else {
                                clearInterval(intervalDesignCircle);
                            };
                        };
                    };
                };

                function setStoreVisible() {
                    setTimeout(function() {
                        menuElementsRefs.textStoreS.current.setAttribute("visibility", "visible");
                    }, 30);
                    setTimeout(function() {
                        menuElementsRefs.textStoreT.current.setAttribute("visibility", "visible");
                    }, 60);
                    setTimeout(function() {
                        menuElementsRefs.textStoreO.current.setAttribute("visibility", "visible");
                    }, 90);
                    setTimeout(function() {
                        menuElementsRefs.textStoreR.current.setAttribute("visibility", "visible");
                    }, 120);
                    setTimeout(function() {
                        menuElementsRefs.textStoreE.current.setAttribute("visibility", "visible");
                        menuElementsRefs.linkStore.current.setAttribute("visibility", "visible");
                    }, 150);
                };

                let pathStoreOffset = 257;
                let intervalStoreTimePrevious = Date.now();
                let intervalStore = setInterval(offsetStore, intervalDelay);
                function offsetStore() {
                    let intervalDeltaTime = Date.now() - intervalStoreTimePrevious;
                    if (pathStoreOffset > 0) {
                        pathStoreOffset = Math.max(pathStoreOffset - intervalDeltaTime * intervalSpeed, 0);
                        menuElementsRefs.pathStore.current.setAttribute("stroke-dashoffset", pathStoreOffset);
                        intervalStoreTimePrevious = Date.now();
                    } else {
                        clearInterval(intervalStore);
                        setStoreVisible();

                        let circleStoreOffset = 13;
                        let intervalStoreCircleTimePrevious = Date.now();
                        let intervalStoreCircle = setInterval(offsetStoreCircle, intervalDelay);
                        function offsetStoreCircle() {
                            let intervalDeltaTime = Date.now() - intervalStoreCircleTimePrevious;
                            if (circleStoreOffset > 0) {
                                circleStoreOffset = Math.max(circleStoreOffset - intervalDeltaTime * intervalSpeed, 0);
                                menuElementsRefs.circleStore0.current.setAttribute("stroke-dashoffset", circleStoreOffset);
                                menuElementsRefs.circleStore1.current.setAttribute("stroke-dashoffset", circleStoreOffset);
                                intervalStoreCircleTimePrevious = Date.now();
                            } else {
                                clearInterval(intervalStoreCircle);
                            };
                        };
                    };
                };

                setTimeout(function() {
                    menuExpandedRef.current = true;
                    menuRunningRef.current = false;
                    menuElementsRefs.buttonMenu.current.setAttribute("display", "true");
                    menuElementsRefs.menuBg.current.setAttribute("pointer-events", "auto");
                }, 900);

            };

            function collapseMenu() {

                let menuBgOpacity = 0.75;
                let intervalMenuBgTimePrevious = Date.now();
                let intervalMenuBgOpacity = setInterval(offsetCircleHome, intervalDelay);
                function offsetCircleHome() {
                    let intervalDeltaTime = Date.now() - intervalMenuBgTimePrevious;
                    if (menuBgOpacity > 0) {
                        menuBgOpacity = Math.max(menuBgOpacity - intervalDeltaTime * 0.002, 0);
                        menuElementsRefs.menuBg.current.setAttribute("opacity", menuBgOpacity);
                        intervalMenuBgTimePrevious = Date.now();
                    } else {
                        clearInterval(intervalMenuBgOpacity);
                    };
                };

                menuElementsRefs.buttonMenu.current.setAttribute("display", "none");

                menuElementsRefs.linkHome.current.setAttribute("visibility", "hidden");
                menuElementsRefs.linkDigital.current.setAttribute("visibility", "hidden");
                menuElementsRefs.linkGames.current.setAttribute("visibility", "hidden");
                menuElementsRefs.linkDesign.current.setAttribute("visibility", "hidden");
                menuElementsRefs.linkStore.current.setAttribute("visibility", "hidden");

                menuElementsRefs.textHomeE.current.setAttribute("visibility", "hidden");
                menuElementsRefs.textDigitalL.current.setAttribute("visibility", "hidden");
                menuElementsRefs.textGamesS.current.setAttribute("visibility", "hidden");
                menuElementsRefs.textDesignN.current.setAttribute("visibility", "hidden");
                menuElementsRefs.textStoreE.current.setAttribute("visibility", "hidden");

                setTimeout(function() {
                    menuElementsRefs.textHomeM.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textDigitalA.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textGamesE.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textDesignG.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textStoreR.current.setAttribute("visibility", "hidden");
                }, 30);

                setTimeout(function() {
                    menuElementsRefs.textHomeO.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textDigitalT.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textGamesM.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textDesignI.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textStoreO.current.setAttribute("visibility", "hidden");
                }, 60);

                setTimeout(function() {
                    menuElementsRefs.textHomeH.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textDigitalI1.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textGamesA.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textDesignS.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textStoreT.current.setAttribute("visibility", "hidden");

                    let circleHomeOffset = 0;
                    let intervalHomeCircleTimePrevious = Date.now();
                    let intervalCircleHome = setInterval(offsetCircleHome, intervalDelay);
                    function offsetCircleHome() {
                        let intervalDeltaTime = Date.now() - intervalHomeCircleTimePrevious;
                        if (circleHomeOffset < 13) {
                            circleHomeOffset = Math.min(circleHomeOffset + intervalDeltaTime * intervalSpeed, 13);
                            menuElementsRefs.circleHome0.current.setAttribute("stroke-dashoffset", circleHomeOffset);
                            menuElementsRefs.circleHome1.current.setAttribute("stroke-dashoffset", circleHomeOffset);
                            intervalHomeCircleTimePrevious = Date.now();
                        } else {
                            clearInterval(intervalCircleHome);

                            let pathHomeOffset = 0;
                            let intervalHomeTimePrevious = Date.now();
                            let intervalHome = setInterval(offsetHome, intervalDelay);
                            function offsetHome() {
                                let intervalDeltaTime = Date.now() - intervalHomeTimePrevious;
                                if (pathHomeOffset < 169) {
                                    pathHomeOffset = Math.min(pathHomeOffset + intervalDeltaTime * intervalSpeed, 169);
                                    menuElementsRefs.pathHome.current.setAttribute("stroke-dashoffset", pathHomeOffset);
                                    intervalHomeTimePrevious = Date.now();
                                } else {
                                    clearInterval(intervalHome);
                                };
                            };
                        };
                    };

                }, 90);

                setTimeout(function() {
                    menuElementsRefs.textDigitalG.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textGamesG.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textDesignE.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textStoreS.current.setAttribute("visibility", "hidden");

                    let circleGamesOffset = 0;
                    let intervalGamesCircleTimePrevious = Date.now();
                    let intervalGamesCircle = setInterval(offsetGamesCircle, intervalDelay);
                    function offsetGamesCircle() {
                        let intervalDeltaTime = Date.now() - intervalGamesCircleTimePrevious;
                        if (circleGamesOffset < 13) {
                            circleGamesOffset = Math.min(circleGamesOffset + intervalDeltaTime * intervalSpeed, 13);
                            menuElementsRefs.circleGames0.current.setAttribute("stroke-dashoffset", circleGamesOffset);
                            menuElementsRefs.circleGames1.current.setAttribute("stroke-dashoffset", circleGamesOffset);
                            intervalGamesCircleTimePrevious = Date.now();
                        } else {
                            clearInterval(intervalGamesCircle);

                            let pathGamesOffset = 0;
                            let intervalGamesTimePrevious = Date.now();
                            let intervalGames = setInterval(offsetGames, intervalDelay);
                            function offsetGames() {
                                let intervalDeltaTime = Date.now() - intervalGamesTimePrevious;
                                if (pathGamesOffset < 162) {
                                    pathGamesOffset = Math.min(pathGamesOffset + intervalDeltaTime * intervalSpeed, 162);
                                    menuElementsRefs.pathGames.current.setAttribute("stroke-dashoffset", pathGamesOffset);
                                    intervalGamesTimePrevious = Date.now();
                                } else {
                                    clearInterval(intervalGames);
                                };
                            };
                        };
                    };

                    let circleStoreOffset = 0;
                    let intervalStoreCircleTimePrevious = Date.now();
                    let intervalStoreCircle = setInterval(offsetStoreCircle, intervalDelay);
                    function offsetStoreCircle() {
                        let intervalDeltaTime = Date.now() - intervalStoreCircleTimePrevious;
                        if (circleStoreOffset < 13) {
                            circleStoreOffset = Math.min(circleStoreOffset + intervalDeltaTime * intervalSpeed, 13);
                            menuElementsRefs.circleStore0.current.setAttribute("stroke-dashoffset", circleStoreOffset);
                            menuElementsRefs.circleStore1.current.setAttribute("stroke-dashoffset", circleStoreOffset);
                            intervalStoreCircleTimePrevious = Date.now();
                        } else {
                            clearInterval(intervalStoreCircle);

                            let pathStoreOffset = 0;
                            let intervalStoreTimePrevious = Date.now();
                            let intervalStore = setInterval(offsetStore, intervalDelay);
                            function offsetStore() {
                                let intervalDeltaTime = Date.now() - intervalStoreTimePrevious;
                                if (pathStoreOffset < 257) {
                                    pathStoreOffset = Math.min(pathStoreOffset + intervalDeltaTime * intervalSpeed, 257);
                                    menuElementsRefs.pathStore.current.setAttribute("stroke-dashoffset", pathStoreOffset);
                                    intervalStoreTimePrevious = Date.now();
                                } else {
                                    clearInterval(intervalStore);
                                };
                            };
                        };
                    };

                }, 120);

                setTimeout(function() {
                    menuElementsRefs.textDigitalI0.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textDesignD.current.setAttribute("visibility", "hidden");

                    let circleDesignOffset = 0;
                    let intervalDesignCircleTimePrevious = Date.now();
                    let intervalDesignCircle = setInterval(offsetDesignCircle, intervalDelay);
                    function offsetDesignCircle() {
                        let intervalDeltaTime = Date.now() - intervalDesignCircleTimePrevious;
                        if (circleDesignOffset < 13) {
                            circleDesignOffset = Math.min(circleDesignOffset + intervalDeltaTime * intervalSpeed, 13);
                            menuElementsRefs.circleDesign0.current.setAttribute("stroke-dashoffset", circleDesignOffset);
                            menuElementsRefs.circleDesign1.current.setAttribute("stroke-dashoffset", circleDesignOffset);
                            intervalDesignCircleTimePrevious = Date.now();
                        } else {
                            clearInterval(intervalDesignCircle);

                            let pathDesignOffset = 0;
                            let intervalDesignTimePrevious = Date.now();
                            let intervalDesign = setInterval(offsetDesign, intervalDelay);
                            function offsetDesign() {
                                let intervalDeltaTime = Date.now() - intervalDesignTimePrevious;
                                if (pathDesignOffset < 210) {
                                    pathDesignOffset = Math.min(pathDesignOffset + intervalDeltaTime * intervalSpeed, 210);
                                    menuElementsRefs.pathDesign.current.setAttribute("stroke-dashoffset", pathDesignOffset);
                                    intervalDesignTimePrevious = Date.now();
                                } else {
                                    clearInterval(intervalDesign);
                                };
                            };
                        };
                    };

                }, 150);

                setTimeout(function() {
                    menuElementsRefs.textDigitalD.current.setAttribute("visibility", "hidden");

                    let circleDigitalOffset = 0;
                    let intervalDigitalCircleTimePrevious = Date.now();
                    let intervalDigitalCircle = setInterval(offsetDigitalCircle, intervalDelay);
                    function offsetDigitalCircle() {
                        let intervalDeltaTime = Date.now() - intervalDigitalCircleTimePrevious;
                        if (circleDigitalOffset < 13) {
                            circleDigitalOffset = Math.min(circleDigitalOffset + intervalDeltaTime * intervalSpeed, 13);
                            menuElementsRefs.circleDigital0.current.setAttribute("stroke-dashoffset", circleDigitalOffset);
                            menuElementsRefs.circleDigital1.current.setAttribute("stroke-dashoffset", circleDigitalOffset);
                            intervalDigitalCircleTimePrevious = Date.now();
                        } else {
                            clearInterval(intervalDigitalCircle);

                            let pathDigitalOffset = 0;
                            let intervalDigitalTimePrevious = Date.now();
                            let intervalDigital = setInterval(offsetDigital, intervalDelay);
                            function offsetDigital() {
                                let intervalDeltaTime = Date.now() - intervalDigitalTimePrevious;
                                if (pathDigitalOffset < 152) {
                                    pathDigitalOffset = Math.min(pathDigitalOffset + intervalDeltaTime * intervalSpeed, 152);
                                    menuElementsRefs.pathDigital.current.setAttribute("stroke-dashoffset", pathDigitalOffset);
                                    intervalDigitalTimePrevious = Date.now();
                                } else {
                                    clearInterval(intervalDigital);
                                };
                            };
                        };
                    };

                }, 180);

                setTimeout(function() {
                    menuExpandedRef.current = false;
                    menuRunningRef.current = false;
                    menuElementsRefs.buttonMenu.current.setAttribute("display", "true");
                    menuElementsRefs.menuBg.current.setAttribute("pointer-events", "none");
                }, 900);

            };

            if (type === "click") {

                if (!menuExpandedRef.current) {

                    expandMenu();

                } else if (menuExpandedRef.current) {

                    collapseMenu();

                };

            } else if ((type === "scroll" || type === "scrollend") && !menuExpandedRef.current && scrollPos < 0.05 && scrollPos < scrollPrevPosRef.current) {

                expandMenu();

            } else if (type === "scroll" && menuExpandedRef.current && scrollPos > 0.05) {

                collapseMenu();

            } else if (type === "expand" && !menuExpandedRef.current && scrollPos < 0.05) {

                expandMenu();

            } else if (type === "collapse" && menuExpandedRef.current) {

                collapseMenu();

            } else {

                menuRunningRef.current = false;

            };

            scrollPrevPosRef.current = scrollPos;

        };

    };

    function handleCheckoutPanel(state, reset) {
        if(state === true) {
            setCheckoutPanelState(true);
            handleMenu("collapse");
            pageElementsRefs.bodyElement.current.style.overflowY = "hidden";
        } else {

            if (checkoutPanelContentState === "success" || reset === true) {

                localStorage.removeItem("cartContent");

                setCartContentState([]);
                setCheckoutPanelButtonState(false);
                setCheckoutPanelContentState("cart");
                setCheckoutPanelCountryState("select_country");
                setPaypalSuccessState(false);
                setShippingCostState(0);

            };

            setCheckoutPanelState(false);
            pageElementsRefs.bodyElement.current.style.overflowY = "scroll";

        };
    };

    //useEffect on initial render
  	useEffect(() => {

        fetchExRateChfUsd();
                                                                // eslint-disable-next-line
    }, []);

    const router = createBrowserRouter([
        {
            path: "/404",
            element: (<><Error /><Header /><Footer /></>),
        },
        {
            path: "/500",
            element: (<><Error /><Header /><Footer /></>),
        },
        {
            path: "/",
            element: (<><Page /><Header /><Footer /></>),
            children:
            [
                {
                    path: ":page",
                    element: (
                        <Page />
                    ),
                children:
                    [
                        {
                            path: ":section",
                            element: (
                                <Page />
                            ),
                        children:
                            [
                                {
                                    path: ":item",
                                    element: (
                                        <Page />
                                    ),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ]);

    return (
        <AppContext.Provider
            value={{
                handleMenu: handleMenu,
                menuElements: menuElementsRefs,
                menuExpanded: menuExpandedRef.current,
                menuRunning: menuRunningRef.current,
                menuLocked: menuLockedRef.current,
                setMenuLocked: setMenuLocked,
                pageElements: pageElementsRefs,
                mediaLoaderVisState: mediaLoaderVisState,
                setMediaLoaderVisState: setMediaLoaderVisState,
                mediaLoaderOpaState: mediaLoaderOpaState,
                setMediaLoaderOpaState: setMediaLoaderOpaState,
                exRateChfUsdRef: exRateChfUsdRef,
                cartContentState: cartContentState,
                setCartContentState: setCartContentState,
                checkoutPanelState: checkoutPanelState,
                checkoutPanelContentState: checkoutPanelContentState,
                setCheckoutPanelContentState: setCheckoutPanelContentState,
                checkoutPanelCountryState: checkoutPanelCountryState,
                setCheckoutPanelCountryState: setCheckoutPanelCountryState,
                checkoutPanelButtonState: checkoutPanelButtonState,
                setCheckoutPanelButtonState: setCheckoutPanelButtonState,
                paypalSuccessState: paypalSuccessState,
                setPaypalSuccessState: setPaypalSuccessState,
                shippingCostState: shippingCostState,
                setShippingCostState: setShippingCostState,
                handleCheckoutPanel: handleCheckoutPanel,
                scrollToTitle: scrollToTitle,
                scrollToOverview: scrollToOverview,
                scrollToTop: scrollToTop,
                scrollToBottom: scrollToBottom,
                getScrollPosition: getScrollPosition,
                getFadeEndTop: getFadeEndTop,
                getFadeStartBottom: getFadeStartBottom,
                getFadeEndTopPixel: getFadeEndTopPixel,
                getFadeStartBottomPixel: getFadeStartBottomPixel,
                backendUrl: backendUrl
            }}
        >
            <RouterProvider router={router} />
        </AppContext.Provider>
    );
};

export default App;