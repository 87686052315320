export function Debounce(fn, ms) {

    let timer;

    return _ => {

        clearTimeout(timer);
        timer = setTimeout(_ => {

            timer = null;
            fn.apply(this, arguments);

        }, ms);

    };

};

export function Throttle(fn, ms) {

    let wait = false;

    return function () {

        if (!wait) {

            fn.call();
            wait = true;

            setTimeout(function () {

                wait = false;

            }, ms);

        };

    };

};

export function GetSectionName(page) {

    if (page === "store") {

        return (
            "products"
        );

    } else {

        return (
            "projects"
        );
        
    };

};