import { useEffect, useRef, useState, useContext } from 'react';

import { useParams } from 'react-router-dom';

import { AppContext } from '../scripts/context'
import { Debounce } from '../scripts/utilities';
import { ManageFadeSvg, CheckFadeSvg, GetGemSize } from '../scripts/fadeSvg';

import RouteData from '../routeData.json';

import Media from './media'
import Main from './main'
import Detail from './detail'

export default function Page() {

    const { page } = useParams();
    const { section } = useParams();
    const { item } = useParams();

    //const navigate = useNavigate();

    const noScrollOnRouteChangeRef = useRef(false);

    const polyIdsAll = useRef();
    const polyRandomnessAll = useRef();

    const contentHeight = useRef();

    const scrollbarSliderRef = useRef();

    const resizeFinishedTimeoutRef = useRef();

    const previousScrollTime = useRef(Date.now());

    //const sectionRef = useRef("top");

    const scrollVariables = useRef({
        scrollAnimFactorOffset: 0,
        scrollAnimFactorGemScale: 0,
        scrollAnimLengthGemScale: 0,
        scrollAnimStepGemScale: 0
    });

    const [titleVisState, setTitleVisState] = useState("visible");
    const [titleOpaState, setTitleOpaState] = useState(1);
    const [mediaVisState, setMediaVisState] = useState("visible");
    const [mediaOpaState, setMediaOpaState] = useState(1);
    const [mainVisState, setMainVisState] = useState("visible");

    const {
		handleMenu,
		scrollToOverview,
		scrollToTop,
        scrollToBottom,
        getScrollPosition,
        getFadeEndTop,
        getFadeStartBottom,
        getFadeEndTopPixel,
        getFadeStartBottomPixel,
        pageElements
    } = useContext(AppContext);

    function setScrollEffects(polys, randomness, scrollVariables) {

        const scrollPos = getScrollPosition();
        const fadeEndTop = getFadeEndTop();
        const fadeStartBottom = getFadeStartBottom();

        function setScrollbarOffset() {

            scrollbarSliderRef.current.style.transform = "translateY(" + ((window.innerHeight - window.innerHeight ** 2 / contentHeight.current) * scrollPos).toFixed(3) + "px)";

        }

        function setElementsAppearance() {

            if (scrollPos <= fadeEndTop) { //top section

                let animPos = 1 + scrollPos * -1 / fadeEndTop;

                if (animPos < 0.01) {
                    animPos = 0;
                } else if (animPos > 0.99) {
                    animPos = 1;
                };

                animPos = animPos.toFixed(3);

                setMediaOpaState(1);
                setMediaVisState("visible");
                setTitleOpaState(animPos);
                setTitleVisState("visible");

            } else if (scrollPos >= fadeStartBottom && scrollPos < 1) { //bottom section

                let animPos = 1 + (scrollPos - fadeStartBottom) * -1 / (1 - fadeStartBottom);

                if (animPos < 0.01) {
                    animPos = 0;
                } else if (animPos > 0.99) {
                    animPos = 1;
                };

                animPos = animPos.toFixed(3);

                setMediaOpaState(animPos);
                setMediaVisState("visible");
                setTitleOpaState(0);
                setTitleVisState("hidden");

            } else if (scrollPos >= 1) { //bottom

                setMediaOpaState(0);
                setMediaVisState("hidden");
                setTitleOpaState(0);
                setTitleVisState("hidden");

            } else { //main

                setMediaOpaState(1);
                setMediaVisState("visible");
                setTitleOpaState(0);
                setTitleVisState("hidden");

            };

            if (scrollPos >= 0.99) { //bottom

                setMainVisState("hidden");

            } else if (scrollPos <= 0.01) { //top

                setMainVisState("hidden");

            } else { //main

                setMainVisState("visible");

            };

        };

        function setGemScale() {

            if (CheckFadeSvg()) {

                polys.forEach((polyIdsLine, indexLine) => {

                    /* calculate polyScale per line calculation based on: "low2 + (value - low1) * (high2 - low2) / (high1 - low1)" and "Math.min(Math.max(value, min), max)" */

                    let animPos;
                    let polyScale;
                    let fadeLinesTop = getFadeEndTopPixel() / GetGemSize();
                    let fadeLinesBottom = 1 + (getFadeStartBottomPixel() + window.innerWidth) / GetGemSize();

                    if (scrollPos < fadeEndTop && indexLine > fadeLinesTop) {

                        animPos = Math.min(Math.max((scrollPos / fadeEndTop), 0), 1);

                        /* fade in */
                        polyScale = Math.min(Math.max(((animPos - indexLine * scrollVariables.scrollAnimFactorGemScale) / scrollVariables.scrollAnimStepGemScale), 0), 1.02);

                    } else if (scrollPos > fadeStartBottom && indexLine < fadeLinesBottom) {

                        animPos = Math.min(Math.max(((scrollPos - fadeStartBottom) / (1 - fadeStartBottom)), 0), 1);

                        /* fade out */
                        polyScale = Math.min(Math.max((1 + -(animPos - indexLine * scrollVariables.scrollAnimFactorGemScale) / scrollVariables.scrollAnimStepGemScale), 0), 1.02);

                    } else {

                        polyScale = 1.02;

                    };

                    polyIdsLine.forEach((polyId, indexPoly) => {

                        let polyCurrent = document.getElementById(polyId);
                        let oldPolyScale = polyCurrent.style.transform;
                        //let oldPolyScale = polyCurrent.getAttribute("transform");

                        if (scrollPos < fadeEndTop || scrollPos > fadeStartBottom) {

                            let polyScaleRandom = (Math.min((polyScale * randomness[indexLine][indexPoly]), 1.02)).toFixed(3);

                            /* apply polyScale multiplied by randomFactor to element if changed */
                            if (oldPolyScale !== polyScaleRandom) {

                                polyCurrent.style.transform = "scale(" + polyScaleRandom + ")";
                                //polyCurrent.setAttribute("transform", "scale(" + polyScaleRandom + ")")

                            };

                        } else if (oldPolyScale !== 1.02) {

                            polyCurrent.style.transform = "scale(1.02)";
                            //polyCurrent.setAttribute("transform", "scale(1.02)")

                        };

                    });

                });

            };

        };

        setScrollbarOffset();
        setGemScale();
        setElementsAppearance();

        //***************************dynamic url change on scroll***************************

        /*const sectionName = GetSectionName(page);
        const bufferZone = 0.05;

        if (scrollPos > fadeStartBottom - bufferZone && scrollPos < fadeStartBottom + bufferZone && (sectionRef.current === "top" || sectionRef.current === "bottom")) {

            if (window.location.pathname !== "/about") {

                noScrollOnRouteChangeRef.current = true;
                sectionRef.current = "middle";
                navigate("/about");
                console.log(window.location.pathname);

            };

        };*/

    };

    function handleScroll() {

        if (Date.now() - previousScrollTime.current >= 16) {

            handleMenu("scroll");
            setScrollEffects(polyIdsAll.current, polyRandomnessAll.current, scrollVariables.current);

            previousScrollTime.current = Date.now();

        };

    };

    function handleScrollEnd() {

        handleMenu("scrollend");

    };

    let resizeAwaitEnd;

    function handleResize() {

        //scroll to bottom if scroll position was at the bottom before resize (on expand height)
        clearTimeout(resizeAwaitEnd);
        resizeAwaitEnd = setTimeout(handleResizeEnd(), 100);

        function handleResizeEnd() {

            const scrollPos = getScrollPosition();

            if (scrollPos > 0.9) {
                scrollToBottom("instant");
            };
    
        };

        setBodyHeight();
        Debounce(updateFadeSvg, 50);
        setScrollEffects(polyIdsAll.current, polyRandomnessAll.current, scrollVariables.current);

        //update fade svg and scroll effects on resize end, since update fade svg might not have been called on final size due to debounce
        clearTimeout(resizeFinishedTimeoutRef.current);
        resizeFinishedTimeoutRef.current = setTimeout(function(){
            updateFadeSvg();
            setScrollEffects(polyIdsAll.current, polyRandomnessAll.current, scrollVariables.current);
        }, 400);

    };

    function updateFadeSvg() {

        let manageFadeResult = ManageFadeSvg();
        polyIdsAll.current = manageFadeResult.polys;
        polyRandomnessAll.current = manageFadeResult.randomness;

        scrollVariables.current.scrollAnimLengthGemScale = 100;
        scrollVariables.current.scrollAnimFactorGemScale = 1 / (polyIdsAll.current.length + scrollVariables.current.scrollAnimLengthGemScale);
        scrollVariables.current.scrollAnimStepGemScale = scrollVariables.current.scrollAnimLengthGemScale * scrollVariables.current.scrollAnimFactorGemScale;

    };

    function setBodyHeight() {

        contentHeight.current = pageElements.mainContainerContent.current.clientHeight;

        pageElements.bodyElement.current.style.height = contentHeight.current + "px";
        scrollbarSliderRef.current.style.height = window.innerHeight ** 2 / contentHeight.current + "px";

    };

    //useEffect on intial render
    useEffect(() => {

        setBodyHeight();
        updateFadeSvg();

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("scrollend", handleScrollEnd);
        window.addEventListener("resize", handleResize);

        return () => {

            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("scrollend", handleScrollEnd);
            window.removeEventListener("resize", handleResize);

        };
                                                                    // eslint-disable-next-line
    }, []);


    //useEffect on page, section or item parameter change
    useEffect(() => {

        setBodyHeight();
        updateFadeSvg();
        setScrollEffects(polyIdsAll.current, polyRandomnessAll.current, scrollVariables.current);

        // combine item, page, and section to get the path
        let path = `/${page || ""}${section ? "/" + section : ""}${item ? "/" + item : ""}`;

        // set document title to the title from RouteData
        const routeObj = RouteData.find((element) => element.path === path);
        document.title = routeObj ? routeObj.title : "TEST NOT FOUND - njb design®";

        // Scroll depending on the route
        if (item) {

          scrollToBottom("smooth");

        } else if ((section || page === "about") && section !== "nils") {

            if (!noScrollOnRouteChangeRef.current) scrollToOverview("smooth");
            noScrollOnRouteChangeRef.current = false;

        } else if (section === "nils") {

            if (!noScrollOnRouteChangeRef.current) scrollToBottom("smooth");
            noScrollOnRouteChangeRef.current = false;
  
        } else {

            if (!noScrollOnRouteChangeRef.current) scrollToTop("smooth");
            noScrollOnRouteChangeRef.current = false;

        }
                                                                    // eslint-disable-next-line
    }, [page, section, item]);

    return (
        <main id="page">
            <Detail />
            <Media 
                titleVis={titleVisState}
                titleOpa={titleOpaState}
                mediaVis={mediaVisState}
                mediaOpa={mediaOpaState}
            />
            <Main
                mainVis={mainVisState}
            />
            <div id="scrollbar_container">
                <div id="scrollbar_slider" ref={scrollbarSliderRef}></div>
            </div>
        </main>
    )

};