export function ManageFadeSvg() {

    const svgns = "http://www.w3.org/2000/svg";

    let windowWidth = window.innerWidth;
    let windowHeight = document.body.scrollHeight;

    let gemSize = GetGemSize();
    let gemSize35Percent = gemSize * 0.35;
    let gemSize65Percent = gemSize * 0.65;

    let gemsAmountWidth = Math.ceil(windowWidth / gemSize);
    let gemsAmountHeight = Math.ceil(windowHeight / gemSize);
    let percentPerPixelWidth = 100 / windowWidth;
    let percentPerPixelHeight = 100 / windowHeight;

    let polys = [];
    let randomness = [];

    function drawPolys(lineNumber,gemNumber) {

        let gemSizeGemNumber = gemSize*(gemNumber+1);
        let gemSizeGemNumberSubGemSize = gemSizeGemNumber-gemSize;

        let gemSizeLineNumber = gemSize*(lineNumber+1);
        let gemSizeLineNumberSubGemSize = gemSizeLineNumber-gemSize;

        let drawPolysResult = [];

        for (let pNumber = 0; pNumber <= 1; pNumber++) {

            if (pNumber === 0) {
                /* lower poly */
                var pPoints = (gemSizeGemNumber + "," + gemSizeLineNumber + " " + gemSizeGemNumberSubGemSize + "," + gemSizeLineNumber + " " + gemSizeGemNumberSubGemSize + "," + gemSizeLineNumberSubGemSize);
                var pOrig = ((percentPerPixelWidth*(gemSizeGemNumberSubGemSize+gemSize35Percent)).toFixed(3) + "% " + (percentPerPixelHeight*(gemSizeLineNumberSubGemSize+gemSize65Percent)).toFixed(3) + "%");
            } else {
                /* upper poly */
                pPoints = (gemSizeGemNumberSubGemSize + "," + gemSizeLineNumberSubGemSize + " " + gemSizeGemNumber + "," + gemSizeLineNumberSubGemSize + " " + gemSizeGemNumber + "," + gemSizeLineNumber);
                pOrig = ((percentPerPixelWidth*(gemSizeGemNumberSubGemSize+gemSize65Percent)).toFixed(3) + "% " + (percentPerPixelHeight*(gemSizeLineNumberSubGemSize+gemSize35Percent)).toFixed(3) + "%");
            };

            let pId = ("p" + pNumber + "_l" + lineNumber + "_g" + gemNumber);

            let p = document.createElementNS(svgns, "polygon");

            p.setAttribute("id", pId);
            p.setAttribute("points", pPoints);
            p.setAttribute("transform-origin", pOrig);
            //p.setAttribute("transform", "scale(0)");
            p.setAttribute("style", "transform: scale(0);");
            //p.setAttribute("style", "transition: transform 0.010s;");
            p.setAttribute("style", "will-change: transform;");

            drawPolysResult.push(p);

        };

        return drawPolysResult;

    };

    if (!CheckFadeSvg()) {

        /* create svg */
        var svg = document.createElementNS(svgns, "svg");
        svg.setAttribute("id", "fade_svg");
        svg.setAttribute("xmlns", svgns);
        svg.setAttribute("x", "0px");
        svg.setAttribute("y", "0px");
        svg.setAttribute("width", (windowWidth + "px"));
        svg.setAttribute("height", (windowHeight + "px"));
        svg.setAttribute("viewBox", ("0 0 " + windowWidth + " " + windowHeight));
        svg.setAttribute("preserveAspectRatio", "xMinYMin");

        /* create defs */
        var defs = document.createElementNS(svgns, 'defs');
        svg.appendChild(defs);        

        /* create clip path */
        var clipPath = document.createElementNS(svgns, 'clipPath');
        clipPath.setAttributeNS(null, "id", "fade_path");
        //clipPath.setAttributeNS(null, "clipPathUnits", "objectBoundingBox");
        defs.appendChild(clipPath);

        for (var lineNumber = 0; lineNumber < gemsAmountHeight; lineNumber++) {

            var polyIdsLine = [];
            var randomnessLine = [];

            for (var gemNumber = 0; gemNumber < gemsAmountWidth; gemNumber++) {

                var gemNew = drawPolys(lineNumber,gemNumber);

                for (var pNew = 0; pNew <= 1; pNew++){

                    clipPath.appendChild(gemNew[pNew]);
                    polyIdsLine.push(gemNew[pNew].id);
        
                    /* calculate randomFactor based on lineNumber and gemNumber */
                    let seed = [lineNumber,gemNumber].join("");
                    let x = Math.sin(seed++) * 10000;
                    let randomFactor = x - Math.floor(x);
                    randomFactor = randomFactor + 1;

                    randomnessLine.push(randomFactor);

                };

            };

            polys.push(polyIdsLine);
            randomness.push(randomnessLine);

        };

        /* add complete svg to document main_container */
        document.getElementById("main_container").appendChild(svg);

    } else {

        svg = document.getElementById("fade_svg");
        svg.setAttribute("width", (windowWidth + "px"));
        svg.setAttribute("height", (windowHeight + "px"));
        svg.setAttribute("viewBox", ("0 0 " + windowWidth + " " + windowHeight));

        clipPath = document.getElementById("fade_path");

        clipPath.replaceChildren();

        for (lineNumber = 0; lineNumber < gemsAmountHeight; lineNumber++) {

            polyIdsLine = [];
            randomnessLine = [];

            for (gemNumber = 0; gemNumber < gemsAmountWidth; gemNumber++) {

                gemNew = drawPolys(lineNumber,gemNumber);

                for (pNew = 0; pNew <= 1; pNew++){

                    clipPath.appendChild(gemNew[pNew]);
                    polyIdsLine.push(gemNew[pNew].id);
        
                    /* calculate randomFactor based on lineNumber and gemNumber */
                    let seed = [lineNumber,gemNumber].join("");
                    let x = Math.sin(seed++) * 10000;
                    let randomFactor = x - Math.floor(x);
                    randomFactor = randomFactor + 1;

                    randomnessLine.push(randomFactor);

                };

            };

            polys.push(polyIdsLine);
            randomness.push(randomnessLine);

        };

    };

    return {
        polys: polys.reverse(),
        randomness: randomness.reverse()
    };

};

export function CheckFadeSvg() {

    if (document.getElementById("fade_path")) {
        return true;
    } else {
        return false;
    };

};

export function GetGemSize() {

    let gemSize = 128;

    if (window.innerWidth < 1300 && window.matchMedia("(pointer: coarse)").matches && window.innerWidth < window.innerHeight) {
        gemSize = window.innerWidth / 3;
    };
    return gemSize;

};