import { useContext, useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';

import { AppContext } from '../scripts/context'

export default function Media( {mediaVis, mediaOpa, titleVis, titleOpa} ) {

    const { page } = useParams();

    const {
        handleMenu,
        mediaLoaderVisState,
        setMediaLoaderVisState,
        mediaLoaderOpaState,
        setMediaLoaderOpaState,
		scrollToTitle
    } = useContext(AppContext);

    const mediaLoaderTimeoutRef = useRef();

    let media = {
        img:
        <img id="media" alt="njbdesign_xthrust_0.jpg" src="/media/njbdesign_xthrust_0.jpg"/>,

        title:
        <div id="title">WELCOME</div>,

        text:
        <div id="title_text">Benefit from our expertise to design efficient, reliable and timeless solutions</div>,
    };

    function handleReadMoreClick() {

        scrollToTitle("smooth");

    };

    function handleMediaClick() {

        handleMenu("collapse");

    };

    function handleMediaOnLoad() {

        let mediaLoaderOpacity = mediaLoaderOpaState;
        let intervalMediaLoaderOpacity = setInterval(setMediaLoaderOpacity, 5);
        function setMediaLoaderOpacity() {
            if (mediaLoaderOpacity > 0) {
                mediaLoaderOpacity = Math.max(mediaLoaderOpacity - 0.04, 0);
                setMediaLoaderOpaState(mediaLoaderOpacity);
            } else {
                clearInterval(intervalMediaLoaderOpacity);
                setMediaLoaderVisState("hidden");
            };

        };

    };

    if (!page || page === "about") {

        media.imgsrc = "/media/njbdesign_xthrust_0.jpg";
        media.imgalt = "njbdesign_xthrust_0.jpg";
        media.title = <div id="title">WELCOME</div>;
        media.text = <div id="title_text">Benefit from our expertise to design efficient, reliable and timeless solutions</div>;

    } else if (page === "digital") {

        media.imgsrc = "/media/suss_jetx_m_1.jpg";
        media.imgalt = "suss_jetx_m_1.jpg";
        media.title = <div id="title">DIGITAL</div>;
        media.text = <div id="title_text">Digital production to make sense of reality</div>;

    } else if (page === "games") {

        media.imgsrc = "/media/njbdesign_xthrust_1.jpg";
        media.imgalt = "njbdesign_xthrust_1.jpg";
        media.title = <div id="title">GAMES</div>;
        media.text = <div id="title_text">Independent projects and unique assets that will keep you entertained</div>;

    } else if (page === "design") {

        media.imgsrc = "/media/njbdesign_wristband_0.jpg";
        media.imgalt = "njbdesign_wristband_0.jpg";
        media.title = <div id="title">DESIGN</div>;
        media.text = <div id="title_text">Custom design and engineering that reflects identity</div>;

    } else if (page === "store") {

        media.imgsrc = "/media/njbdesign_store_0.jpg";
        media.imgalt = "njbdesign_store_0.jpg";
        media.title = <div id="title">STORE</div>;
        media.text = <div id="title_text">Back our ventures by getting selected products to represent our brands</div>;

    };

    //useEffect on mediaLoaderVisState change (mediaLoaderVisState gets set to visible on menu navigation)
    useEffect(() => {

        clearTimeout(mediaLoaderTimeoutRef.current);

        mediaLoaderTimeoutRef.current = setTimeout(function() {
            if (mediaLoaderVisState !== "hidden") {
                handleMediaOnLoad();
            };
        }, 2000);
                                                                        // eslint-disable-next-line
    }, [mediaLoaderVisState]);

    return (
        <div id="media_container" style={{visibility: mediaVis, opacity: mediaOpa}}>
            <div id="media_container_content">
                <img id="media" onClick={() => {handleMediaClick()}} onLoad={() => {handleMediaOnLoad()}} alt={media.imgalt} src={media.imgsrc}/>
            </div>
            <div id="media_loader_container" style={{visibility: mediaLoaderVisState, opacity: mediaLoaderOpaState}}></div>
            <div id="title_container" style={{visibility: titleVis, opacity: titleOpa}}>
                {media.title}
                {media.text}
                <div id="button_read_more" onClick={() => {handleReadMoreClick()}}>Read more...</div>
            </div>
        </div>
    );

};